<template>
    <div>
      <span>Hello </span>
    </div>
  </template>
  
  <script>
  import router from '@/router'
  import useJwt from '@/auth/jwt/useJwt'
  
  export default {
    components: {},
    data(){
      return{
          preview:''
      }
    },
    created() {
      const accessToken = useJwt.getToken('accessToken') 
      if(router.currentRoute.params.id){
        // Get edit form data
        this.$http.get('https://api.purplepatch.online/advertiser/campaigns/view/'+router.currentRoute.params.id, { 
          headers: {
            Authorization:'Bearer ' + accessToken,
            'x-access-token': accessToken 
          } 
        })
        .then(response => {
            this.preview = response.data;
          console.log(response);
        })
        .catch( error => {
          console.log(error);
        })
      }
    },
  }
  </script>
  